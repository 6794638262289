import { Fragment } from 'react';

import { Helmet } from 'react-helmet';
import { graphql, useStaticQuery } from 'gatsby';
import EventsTemplate from 'templates/events';

import { ZoomWebinarRecurrenceType } from 'types/zoom';

import makeMockZoomWebinar from 'components/content-blocks/webinar-registration/util/make-mock-zoom-webinar';
import mockACFWebinarPageTemplate from 'components/content-blocks/webinar-registration/util/mock-acf-webinar-page-template';

const WebinarTestPageRecurring = () => {

  const defaultCopy = useStaticQuery(graphql`{
    wordpressAcfOptions {
      options {
        ...DefaultWebinarCopy
      }
    }
  }`);

  const getMockWebinar = () => {
    return makeMockZoomWebinar(
      'Test Webinar Recurring',
      null,
      [
        {
          duration: 60,
          id: '1',
          startTime: (() => {
            const startDate = new Date();
            startDate.setDate(startDate.getDate() + 2);
            return startDate.toString();
          })(),
          status: 'available',
        },
        {
          duration: 60,
          id: '2',
          startTime: (() => {
            const startDate = new Date();
            startDate.setDate(startDate.getDate() + 7);
            return startDate.toString();
          })(),
          status: 'available',
        },
      ],
      {
        endDateTime: '',
        repeatInterval: 1,
        type: ZoomWebinarRecurrenceType.WEEKLY,
        weeklyDays: '2',
      }
    );
  };
  console.log(getMockWebinar);
  return (
    <Fragment>
      <EventsTemplate
        mockRegistration={ true }
        pageContext={ {
          acf: {
            webinar_page_template: mockACFWebinarPageTemplate,
          },
          defaultTextCopy: defaultCopy.wordpressAcfOptions.options.webinar_registration_default_copy,
          upcomingOneTimeWebinars: [],
          zoomWebinar: getMockWebinar(),
        } }
      />
      <Helmet>
        <meta name="robots" content="noimageindex, nofollow, nosnippet" />
      </Helmet>
    </Fragment>
  );
};

export default WebinarTestPageRecurring;
